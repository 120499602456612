import { defineMessages } from "react-intl";

enum Split {
  BOOTY = "booty",
  CORE = "core",
  LOWER_UPPER = "lowerUpper",
  FULL_BODY = "fullBody",
  MAIN_LIFT = "mainLift",
  PATTERNS = "patterns",
}

export default Split;

export const messages = defineMessages({
  [Split.BOOTY]: {
    defaultMessage: "Booty",
  },
  [Split.CORE]: {
    defaultMessage: "Core",
  },
  [Split.LOWER_UPPER]: {
    defaultMessage: "Lower/upper",
  },
  [Split.FULL_BODY]: {
    defaultMessage: "Full body",
  },
  [Split.MAIN_LIFT]: {
    defaultMessage: "Main lift",
  },
  [Split.PATTERNS]: {
    defaultMessage: "Patterns",
  },
});

export const splits: Split[] = [
  Split.LOWER_UPPER,
  Split.PATTERNS,
  Split.FULL_BODY,
  Split.MAIN_LIFT,
  Split.BOOTY,
  Split.CORE,
];
