import * as React from "react";
import { useIntl } from "react-intl";

import type Category from "@/enums/category";
import { messages as messagesCategory } from "@/enums/category";
import type Goal from "@/enums/goal";
import { messages as messagesGoal } from "@/enums/goal";
import type Difficulty from "@/enums/difficulty";
import { messages as messagesDifficulty } from "@/enums/difficulty";
import type Split from "@/enums/split";
import { messages as messagesSplit } from "@/enums/split";

export const useNameCategory = () => {
  const intl = useIntl();

  return React.useCallback(
    (code: string | null) => {
      if (code !== null && code in messagesCategory) {
        return intl.formatMessage(messagesCategory[code as Category]);
      }

      return code;
    },
    [intl],
  );
};

export const useNameGoal = () => {
  const intl = useIntl();

  return React.useCallback(
    (code: string | null) => {
      if (code !== null && code in messagesGoal) {
        return intl.formatMessage(messagesGoal[code as Goal]);
      }

      return code;
    },
    [intl],
  );
};

export const useNameDifficulty = () => {
  const intl = useIntl();

  return React.useCallback(
    (code: string | null) => {
      if (code !== null && code in messagesDifficulty) {
        return intl.formatMessage(messagesDifficulty[code as Difficulty]);
      }

      return code;
    },
    [intl],
  );
};

export const useNameSplit = () => {
  const intl = useIntl();

  return React.useCallback(
    (code: string | null) => {
      if (code !== null && code in messagesSplit) {
        return intl.formatMessage(messagesSplit[code as Split]);
      }

      return code;
    },
    [intl],
  );
};
