import * as React from "react";
import classnames from "classnames";

import styles from "./LoaderBox.module.css";

type Props = {
  className?: string;
};

export default function LoaderBox({ className }: Props) {
  return (
    <div className={classnames("relative overflow-hidden bg-ink-background opacity-5", className)}>
      <div className={classnames("h-full w-full opacity-25", styles.wave)} />
    </div>
  );
}
