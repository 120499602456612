import * as React from "react";
import classnames from "classnames";

const colorsGain = {
  normal: "text-product",
  inverse: "text-ink-product",
};

const colorsWagon = {
  normal: "text-ink-background",
  inverse: "text-ink-product",
};

type Color = keyof typeof colorsGain;

type Props = {
  className?: string;
  color?: Color;
};

export default function GainWagon({ className, color = "normal" }: Props) {
  return (
    <div className={classnames(className, "font-alt italic uppercase")}>
      <span className={classnames(colorsGain[color], "font-bolder")}>Gain</span>
      <span className={classnames(colorsWagon[color], "font-lighter")}>Wagon</span>
    </div>
  );
}
