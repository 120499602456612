import { defineMessages } from "react-intl";

enum Category {
  BANDS = "bands",
  BARBELL = "barbell",
  BODYBUILDING = "bodybuilding",
  BODYWEIGHT = "bodyweight",
  BOOTYBUILDING = "bootybuilding",
  CALISTHENICS = "calisthenics",
  COMPOUND = "compound",
  GYMNASTICS = "gymnastics",
  HOME = "home",
  HYPERTROPHY = "hypertrophy",
  INTENSITY = "intensity",
  ISOLATION = "isolation",
  KETTLEBELL = "kettlebell",
  METCON = "metcon",
  POWERBUILDING = "powerbuilding",
  POWERLIFTING = "powerlifting",
  SPORT = "sport",
  STRONGMAN = "strongman",
  VOLUME = "volume",
  WEIGHTLIFTING = "weightlifting",
}

export default Category;

export const messages = defineMessages({
  [Category.BANDS]: {
    defaultMessage: "Bands",
  },
  [Category.BARBELL]: {
    defaultMessage: "Barbell",
  },
  [Category.BODYBUILDING]: {
    defaultMessage: "Bodybuilding",
  },
  [Category.BODYWEIGHT]: {
    defaultMessage: "Bodyweight",
  },
  [Category.BOOTYBUILDING]: {
    defaultMessage: "Bootybuilding",
  },
  [Category.CALISTHENICS]: {
    defaultMessage: "Calisthenics",
  },
  [Category.COMPOUND]: {
    defaultMessage: "Compound",
  },
  [Category.GYMNASTICS]: {
    defaultMessage: "Gymnastics",
  },
  [Category.HOME]: {
    defaultMessage: "Home",
  },
  [Category.HYPERTROPHY]: {
    defaultMessage: "Hypertrophy",
  },
  [Category.INTENSITY]: {
    defaultMessage: "Intensity",
  },
  [Category.ISOLATION]: {
    defaultMessage: "Isolation",
  },
  [Category.KETTLEBELL]: {
    defaultMessage: "Kettlebell",
  },
  [Category.METCON]: {
    defaultMessage: "Metcon",
  },
  [Category.POWERBUILDING]: {
    defaultMessage: "Powerbuilding",
  },
  [Category.POWERLIFTING]: {
    defaultMessage: "Powerlifting",
  },
  [Category.SPORT]: {
    defaultMessage: "Sport",
  },
  [Category.STRONGMAN]: {
    defaultMessage: "Strongman",
  },
  [Category.VOLUME]: {
    defaultMessage: "Volume",
  },
  [Category.WEIGHTLIFTING]: {
    defaultMessage: "Weightlifting",
  },
});

export const categories: Category[] = [
  Category.BANDS,
  Category.BARBELL,
  Category.BODYBUILDING,
  Category.BODYWEIGHT,
  Category.BOOTYBUILDING,
  Category.CALISTHENICS,
  Category.COMPOUND,
  Category.GYMNASTICS,
  Category.HOME,
  Category.HYPERTROPHY,
  Category.INTENSITY,
  Category.ISOLATION,
  Category.KETTLEBELL,
  Category.METCON,
  Category.POWERBUILDING,
  Category.POWERLIFTING,
  Category.SPORT,
  Category.STRONGMAN,
  Category.VOLUME,
  Category.WEIGHTLIFTING,
];
