/* eslint-disable fp/no-rest-parameters */
import * as React from "react";
import classnames from "classnames";

export type Props = Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "onClick"> & {
  onClick?: (ev: React.MouseEvent<HTMLButtonElement>) => void;
};

export default function LinkButton({ className, children, ...rest }: Props) {
  return (
    <button className={classnames("inline-block underline", className)} type="button" {...rest}>
      {children}
    </button>
  );
}
