import * as React from "react";

import { useNameGoal } from "@/services/intl/enums";

type Props = {
  code: string | null | undefined;
};

function NameGoal({ code }: Props) {
  const nameGoal = useNameGoal();

  return <>{nameGoal(code ?? null)}</>;
}

export default NameGoal;
