/* eslint-disable fp/no-rest-parameters, react/display-name */
import * as React from "react";

import type { Props as LinkProps } from "components/Link";
import Link from "components/Link";
import type { Props as LinkButtonProps } from "components/LinkButton";
import LinkButton from "components/LinkButton";

export const text =
  (props: Omit<React.HTMLAttributes<HTMLSpanElement>, "children">) =>
  (...chunks: string[]) =>
    <span {...props}>{chunks}</span>;

export const link =
  (props: Omit<LinkProps, "children">) =>
  (...chunks: string[]) =>
    <Link {...props}>{chunks}</Link>;

export const button =
  (props: Omit<LinkButtonProps, "children">) =>
  (...chunks: string[]) =>
    <LinkButton {...props}>{chunks}</LinkButton>;
