import * as React from "react";
import type { CombinedError } from "@urql/core";
import { defineMessages, useIntl } from "react-intl";

import ErrorCode from "@/api/errors";

export const messages = defineMessages({
  [ErrorCode.EMAIL_TAKEN]: {
    defaultMessage: "Sorry, this email is already taken! 🙀 Please pick a different one.",
  },
  [ErrorCode.NOT_FOUND]: {
    defaultMessage: "Don't know what you've been looking for, but couldn't find it.",
  },
  [ErrorCode.SERVER]: {
    defaultMessage: "Oops, our servers don't even lift! 😡 Please try to refresh the page.",
  },
  [ErrorCode.UNAUTHORIZED]: {
    defaultMessage: "Sorry, you're not authorized to do this! 🤓",
  },
  [ErrorCode.VALIDATION]: {
    defaultMessage:
      "Server says you entered something that is either too long or in an invalid format. 🧐 Please check form values and try again.",
  },
});

const useFormatError = () => {
  const intl = useIntl();

  return React.useCallback(
    (error: CombinedError) => {
      const code = error.graphQLErrors
        .map((e) => e.extensions.code as string | undefined)
        .find((c) => c != null && c in messages);
      if (code == null) {
        return intl.formatMessage(messages[ErrorCode.SERVER]);
      }
      return intl.formatMessage(messages[code as ErrorCode]);
    },
    [intl],
  );
};

export default useFormatError;
