import { defineMessages } from "react-intl";

enum Difficulty {
  BEGINNER = "beginner",
  INTERMEDIATE = "intermediate",
  ADVANCED = "advanced",
}

export default Difficulty;

export const messages = defineMessages({
  [Difficulty.BEGINNER]: {
    defaultMessage: "Beginner",
  },
  [Difficulty.INTERMEDIATE]: {
    defaultMessage: "Intermediate",
  },
  [Difficulty.ADVANCED]: {
    defaultMessage: "Advanced",
  },
});

export const difficulties: Difficulty[] = [
  Difficulty.BEGINNER,
  Difficulty.INTERMEDIATE,
  Difficulty.ADVANCED,
];
