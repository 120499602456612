import * as React from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import Routes from "@/enums/routes";
import Link from "components/Link";
import { useCookies } from "@/services/cookies/context";
import LinkButton from "components/LinkButton";
import Layout from "@/enums/layout";

type Props = {
  className?: string;
};

export default function Cookies({ className }: Props) {
  const { agreed, onAgree, onChange } = useCookies();

  const handleClick = React.useCallback(() => {
    onAgree(true);
    onChange({
      preferences: true,
      analytics: true,
      marketing: true,
    });
  }, [onAgree, onChange]);

  if (agreed !== null) {
    return null;
  }

  return (
    <div
      id={Layout.COOKIES}
      className={classnames("fixed z-navbar bottom-0 left-0 p-2 text-base text-xs", className)}
    >
      <div className="relative shadow bg-background-variant py-3 pl-3 pr-10">
        <span className="font-lighter">
          <FormattedMessage
            defaultMessage="We use cookies 🍪. Check our {policy} to learn more or {cookies}."
            values={{
              policy: (
                <Link className="link-ink-background" href={Routes.PAGES_PRIVACY}>
                  <FormattedMessage defaultMessage="Privacy Policy" />
                </Link>
              ),
              cookies: (
                <Link className="link-ink-background" href={Routes.PAGES_COOKIES}>
                  <FormattedMessage defaultMessage="Customize your Cookie Consent" />
                </Link>
              ),
            }}
          />
        </span>

        <LinkButton
          className="link-ink-background absolute bottom-3 right-3 font-bold"
          aria-label="accept cookies"
          onClick={handleClick}
        >
          <FormattedMessage defaultMessage="OK" />
        </LinkButton>
      </div>
    </div>
  );
}
