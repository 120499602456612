import * as React from "react";
import classnames from "classnames";

type Props = {
  className?: string;
  children: React.ReactNode;
  href: string;
};

function SocialIcon({ className, children, href }: Props) {
  return (
    <a
      className={classnames(
        className,
        "relative inline-block h-7 w-7 p-1 rounded-full border-2 border-product",
      )}
      href={href}
      rel="noopener noreferrer"
      target="_blank"
    >
      <span className="text-ink-background">{children}</span>
    </a>
  );
}

export default SocialIcon;
