import * as React from "react";
import { FormattedMessage } from "react-intl";
import type { CombinedError } from "@urql/core";

import Alert from "components/Alert";
import ErrorCode from "@/api/errors";
import useFormatError, { messages } from "@/services/errors/useFormatError";

type Props = {
  className?: string;
  error?: CombinedError;
};

function Error({ className, error }: Props) {
  const formatError = useFormatError();

  return (
    <Alert className={className} type="error">
      {error == null ? <FormattedMessage {...messages[ErrorCode.SERVER]} /> : formatError(error)}
    </Alert>
  );
}

export default Error;
