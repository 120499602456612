import * as React from "react";
import { MdClose, MdInfo, MdDone, MdWarning, MdError } from "react-icons/md";
import classnames from "classnames";

const types = {
  info: "bg-info-600 text-info-50",
  success: "bg-success-700 text-info-50",
  warning: "bg-warning-700 text-info-50",
  error: "bg-error-700 text-info-50",
};

const buttonIcon = {
  info: "group-hover:text-info-100",
  success: "group-hover:text-info-100",
  warning: "group-hover:text-info-100",
  error: "group-hover:text-info-100",
};

export type Type = keyof typeof types;

type Props = {
  className?: string;
  children: React.ReactNode;
  type: Type;
  onClose?: () => void;
};

function Alert({ className, children, type, onClose }: Props) {
  return (
    <div
      className={classnames(
        "relative py-4 px-12 my-4 w-full rounded-lg font-main",
        types[type],
        className,
      )}
    >
      <div className="absolute top-3 left-3">
        {type === "info" && <MdInfo className="icon-base" />}
        {type === "success" && <MdDone className="icon-base" />}
        {type === "warning" && <MdWarning className="icon-base" />}
        {type === "error" && <MdError className="icon-base" />}
      </div>

      <span>{children}</span>

      {onClose && (
        <button
          type="button"
          className="absolute top-3 right-3 group"
          aria-label="close alert"
          onClick={onClose}
        >
          <MdClose className={classnames("icon-base", buttonIcon[type])} />
        </button>
      )}
    </div>
  );
}

export default Alert;
