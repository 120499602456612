import { defineMessages } from "react-intl";

enum Goal {
  STRENGTH = "strength",
  PERFORMANCE = "performance",
  MUSCLE_GAIN = "muscleGain",
  GPP = "gpp",
  FAT_LOSS = "fatLoss",
}

export default Goal;

export const messages = defineMessages({
  [Goal.STRENGTH]: {
    defaultMessage: "Strength",
  },
  [Goal.PERFORMANCE]: {
    defaultMessage: "Performance",
  },
  [Goal.MUSCLE_GAIN]: {
    defaultMessage: "Muscle gain",
  },
  [Goal.GPP]: {
    defaultMessage: "GPP",
  },
  [Goal.FAT_LOSS]: {
    defaultMessage: "Fat loss",
  },
});

export const goals: Goal[] = [
  Goal.STRENGTH,
  Goal.MUSCLE_GAIN,
  Goal.GPP,
  Goal.PERFORMANCE,
  Goal.FAT_LOSS,
];

export const images = {
  // https://cloudinary.com/console/c-47e823c32cecb9e832e7e42274b5e4/media_library/folders/home
  [Goal.STRENGTH]: "goal_strength_vpyohj.jpg",
  [Goal.PERFORMANCE]: "goal_performance_ahx5fb.jpg",
  [Goal.MUSCLE_GAIN]: "goal_muscle_gain_ignx7r.jpg",
  [Goal.GPP]: "goal_gpp_ygi0xv.jpg",
  [Goal.FAT_LOSS]: "goal_fat_loss_fn2h1n.jpg",
};
