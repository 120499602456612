import * as React from "react";
import { FiFacebook, FiInstagram /* , FiTwitter, FiYoutube */ } from "react-icons/fi";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import GainWagon from "components/GainWagon";
import Link from "components/Link";
import Routes from "@/enums/routes";
import SocialIcon from "components/SocialIcon";
import Links from "@/enums/links";
import Layout from "@/enums/layout";

type ChildrenProps = {
  children: React.ReactNode;
};

function Heading({ children }: ChildrenProps) {
  return (
    <span className="inline-block my-1 font-alt text-lg text-ink-background-variant">
      {children}
    </span>
  );
}

type Props = {
  className?: string;
};

export default function Footer({ className }: Props) {
  return (
    <footer
      id={Layout.FOOTER}
      className={classnames(
        "relative px-4 py-6 bg-background border-t-2 border-ink-background-variant",
        className,
      )}
    >
      <GainWagon className="text-3xl" />
      <span className="font-alt text-xs text-ink-background-variant">
        <FormattedMessage defaultMessage="© 2019 GainWagon s.r.o. All Rights Reserved" />
      </span>

      <div className="flex my-8 text-sm leading-loose">
        <div className="flex-1">
          <Heading>
            <FormattedMessage defaultMessage="Legal" />
          </Heading>

          <ul>
            <li>
              <Link href={Routes.PAGES_TERMS}>
                <FormattedMessage defaultMessage="Terms" />
              </Link>
            </li>
            <li>
              <Link href={Routes.PAGES_PRIVACY}>
                <FormattedMessage defaultMessage="Privacy & Cookies" />
              </Link>
            </li>
          </ul>
        </div>

        <div className="flex-1">
          <Heading>
            <FormattedMessage defaultMessage="About" />
          </Heading>

          <ul>
            <li>
              <Link href={Routes.PAGES_FAQ}>
                <FormattedMessage defaultMessage="FAQ & Support" />
              </Link>
            </li>
            <li>
              <Link href={Routes.PAGES_EXERCISES}>
                <FormattedMessage defaultMessage="Exercises Info" />
              </Link>
            </li>
            <li>
              <a className="underline" href="mailto:bro@gainwagon.com?subject=💪">
                <FormattedMessage defaultMessage="Email Us" />
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="flex my-8">
        <div className="flex-1">
          <Heading>
            <FormattedMessage defaultMessage="Social" />
          </Heading>

          <div className="space-x-3">
            <SocialIcon href={Links.FACEBOOK}>
              <FiFacebook />
            </SocialIcon>
            <SocialIcon href={Links.INSTAGRAM}>
              <FiInstagram />
            </SocialIcon>
            {/* <SocialIcon href={Links.TWITTER}>*/}
            {/*  <FiTwitter />*/}
            {/* </SocialIcon>*/}
            {/* <SocialIcon href={Links.YOUTUBE}>*/}
            {/*  <FiYoutube />*/}
            {/* </SocialIcon>*/}
          </div>
        </div>
      </div>
    </footer>
  );
}
