/* eslint-disable fp/no-rest-parameters */
import * as React from "react";
import Image from "next/image";
import type { ImageProps } from "next/image";

import Images from "@/enums/images";

type Props = Omit<ImageProps, "src"> & {
  className?: string;
  imageId?: string | null;
  fallback?: Images;
};

function ImageComponent({ className, imageId, fallback = Images.PLACEHOLDER, ...rest }: Props) {
  return <Image {...rest} className={className} src={imageId ?? fallback} />;
}

export default React.memo(ImageComponent);
